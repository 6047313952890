
import { computed, defineComponent, onMounted, ref, watch } from "vue";

import ContentTypeFilterAll from "./ContentTypeFilterGroupings/ContentTypeFilterAll.vue";
import ContentTypeFilter from "./ContentTypeFilterGroupings/ContentTypeFilter.vue";
import ContentTypesModule from "../store/modules/ContentTypes/contentTypes";
import TableSettingsModule from "../store/modules/TableSettings/tableSettings";
import UIConfigurationModule from "@/store/modules/UIConfiguration/uiConfiguration";
import PatientModule from "../store/modules/Patient/patient";
import UserModule from "../store/modules/User/user";

import { Gender } from "../models/patient/patient";

export default defineComponent({
  name: "SideFilter",
  components: {
    ContentTypeFilterAll,
    ContentTypeFilter,
  },
  props: {
    isFilterOpen: {
      type: Boolean,
      default: false,
    },

    tenantId: {
      default: "",
      type: String,
    },
  },
  setup (props, { emit }) {
    const filterStatus = ref(props.isFilterOpen);
    const footerHeight = computed(() => TableSettingsModule.footerHeight);
    const useContentTypeGroups = computed(() => UIConfigurationModule.getUIConfiguration.UseContentTypeGroups);
    const sortByArray = [
      { value: "most-relevant", label: "Most relevant" },
      { value: "newest-relevant", label: "Newest relevant" },
      { value: "favorites", label: "Favorites" },
      { value: "name", label: "Name" },
      { value: "content-type", label: "Content Type" },
    ];
    const sortBySelection = ref<number>(0);
    const sortChange = async (): Promise<void> => {
      try {
        emit("sortChange", sortByArray[sortBySelection.value].value);
        await UserModule.updateFiltersSort(sortBySelection.value);
      } catch (e: any) {
        console.error("could not update userSettings", e);
      }
    };

    const mediaTypeArray = [
      { value: 0, label: "All" },
      { value: 1, label: "Documents" },
      { value: 2, label: "Videos" },
    ];
    const mediaTypeSelected = ref(0);

    const sexArray = computed(() => [
      { query: { Code: "B", Name: "All", AlternateCode: "" } as Gender, label: "All" },
      { query: { Code: "M", Name: "Male", AlternateCode: "" } as Gender, label: "Male" },
      { query: { Code: "F", Name: "Female", AlternateCode: "" } as Gender, label: "Female" },
    ]);
    const sexSelected = ref(0);

    const ageArray = [
      { Id: 1, Value: "Infant (0-11 mo)", min: 0, max: 0.99 },
      { Id: 2, Value: "Child (1-12 yrs)", min: 1, max: 12 },
      { Id: 3, Value: "Teen (12-18 yrs)", min: 12, max: 18 },
      { Id: 4, Value: "Adult (18-65 yrs)", min: 18, max: 65 },
      { Id: 5, Value: "Senior (65+ yrs)", min: 65, max: 120 },
    ];
    const selectedAgeGroupIds = ref<number[]>([]);
    const ageGroupIdArray = [1, 2, 3, 4, 5];

    const allAgesSelected = computed(() => {
      return ageArray.length === selectedAgeGroupIds.value.length;
    });

    const ageGroupId = computed(() => {
      return PatientModule.getAgeGroupId;
    });

    const ensureAtLeastOneAgeGroup = (): void => {
      if (selectedAgeGroupIds.value.length === 0) {
        selectedAgeGroupIds.value = [ageGroupId.value];
      }
    };

    const sideFiltersChange = (): void => {
      ensureAtLeastOneAgeGroup(); // default to patient age group if none is checked
      emit("sideFiltersChange", {
        sex: sexArray.value[sexSelected.value].query,
        ageGroupIds: selectedAgeGroupIds.value,
        mediaType: mediaTypeSelected.value,
      });
    };

    const selectAllAges = () => {
      allAgesSelected.value
        ? selectedAgeGroupIds.value = [ageGroupId.value]
        : selectedAgeGroupIds.value = ageGroupIdArray;
      sideFiltersChange();
    };

    const contentTypes = computed(() => ContentTypesModule.getContentTypesNames);
    const selectedContentTypes = ref<string[]>([]);

    // TODO: this should be moved to a utility module when this logic is required in another module.
    const applyForcedContentTypes = (candidates: string[]) => {
      const forcedContentTypes = UserModule.getUserSettings.displaySettings.contentTypeFilter.ContentTypesToForce;
      if (forcedContentTypes) {
        for (const typeName of candidates) {
          const contentTypeId = ContentTypesModule.getContentTypeIdByContentTypeName(typeName);
          for (const key of forcedContentTypes) {
            const [mainId, forceId] = key.split("::");
            const enableContentTypeId = forceId;

            if (contentTypeId === Number(mainId)) {
              const fContentTypeIdValueToAdd = ContentTypesModule.getContentTypeNameById(enableContentTypeId);
              if (fContentTypeIdValueToAdd && !candidates.includes(fContentTypeIdValueToAdd)) {
                candidates.push(fContentTypeIdValueToAdd);
              }
            }
          }
        }
      }
    };

    const getContentTypes = async () => {
      const contentTypesFilter = window.localStorage.getItem("contentTypesFilter-" + props.tenantId);
      let candidates: string[];
      if (contentTypesFilter) {
        candidates = JSON.parse(contentTypesFilter);
      } else {
        const contentTypeFilter = UserModule.getUserSettings.displaySettings.contentTypeFilter.ContentTypeFilter;
        candidates = ContentTypesModule.getContentTypes
          .filter(f => !contentTypeFilter || contentTypeFilter.indexOf(f.ContentTypeId) > -1)
          .map(f => f.ContentTypeName);
      }

      applyForcedContentTypes(candidates);
      selectedContentTypes.value = candidates;
    };

    watch(contentTypes, getContentTypes);

    const allContentTypesSelected = computed(() => {
      return contentTypes.value.length === selectedContentTypes.value.length;
    });

    const selectAllTypes = () => {
      allContentTypesSelected.value
        ? selectedContentTypes.value = []
        : selectedContentTypes.value = contentTypes.value;
    };

    const selectOrUnselectType = (types: string[]) => {
      const indexes = selectedContentTypes.value.filter(type => types.includes(type) && contentTypes.value.includes(type))
        .map(type => selectedContentTypes.value.findIndex(selectedType => selectedType.localeCompare(type) === 0))
        .sort((a, b) => a - b);
      const newContentTypes = [...selectedContentTypes.value];
      if (indexes.length) {
        for (let i = 0; i < indexes.length; i++) {
          newContentTypes.splice(indexes[i] - i, 1);
        }
      } else {
        newContentTypes.push(...types);
      }
      selectedContentTypes.value = newContentTypes;
    };

    const getSortBy = async (): Promise<void> => {
      const storedSortBy = window.localStorage.getItem("sortByFilter");
      try {
        if (storedSortBy) {
          const parsedValue = Number.parseInt(storedSortBy);
          sortBySelection.value = parsedValue;
          await UserModule.updateFiltersSort(parsedValue);
          window.localStorage.removeItem("sortByFilter");
        } else {
          const { userSettings } = UserModule.getUserSettings;
          sortBySelection.value = userSettings.FiltersSort ?? 0; // handles the case where the user never had it set in db
        }
      } catch (e: any) {
        console.error("could not update userSettings", e);
      }
    };

    const setInitialFilterValues = async (): Promise<void> => {
      if (selectedAgeGroupIds.value && selectedContentTypes.value) {
        await getSortBy();
        emit("setInitialFilterValues", {
          sortBy: sortByArray[sortBySelection.value].value,
          selectedContentTypes: selectedContentTypes.value,
          mediaType: mediaTypeArray[mediaTypeSelected.value].value,
          sex: sexArray.value[sexSelected.value].query,
          ageGroupIds: selectedAgeGroupIds.value,
        });
        emit("sortChange", sortByArray[sortBySelection.value].value);
      }
    };

    watch(ageGroupId, (newAge, oldAge) => {
      if (newAge !== oldAge) {
        selectedAgeGroupIds.value = [newAge];
        setInitialFilterValues();
      }
    });

    watch(selectedContentTypes, (newSelection, oldSelection) => {
      if (newSelection !== oldSelection) {
        emit("selectedContentTypesChange", selectedContentTypes.value);
        window.localStorage.setItem("contentTypesFilter-" + props.tenantId, JSON.stringify(selectedContentTypes.value));
      }
    });

    const closeFilter = async (): Promise<void> => {
      try {
        emit("closeFilter");
        await UserModule.updateFiltersOpen(false);
      } catch (e: any) {
        console.error("could not update userSettings", e);
      }
    };

    watch(() => props.isFilterOpen, (newValue, oldValue) => {
      if (newValue && newValue !== oldValue) {
        filterStatus.value = true;
      }
    });

    watch(filterStatus, (newValue) => {
      if (!newValue) {
        closeFilter();
      }
    });

    onMounted(async () => {
      const ageGroupId = PatientModule.getAgeGroupId;
      if (ageGroupId) {
        selectedAgeGroupIds.value = [ageGroupId];
      }

      // Pre-select the birth sex filter option that matches the incoming
      // patient's birth sex
      const patientSex = PatientModule.getGender;
      const sexIndex = sexArray.value.findIndex(sex => sex.query.Code === patientSex.Code);
      if (sexIndex >= 0) {
        sexSelected.value = sexIndex;
      }

      await getContentTypes();
      setInitialFilterValues();
    });

    return {
      filterStatus,
      sortByArray,
      sortBySelection,
      sortChange,
      mediaTypeArray,
      mediaTypeSelected,
      sexArray,
      sexSelected,
      ageArray,
      sideFiltersChange,
      contentTypes,
      getContentTypes,
      selectedContentTypes,
      selectedAgeGroupIds,
      selectAllAges,
      allAgesSelected,
      allContentTypesSelected,
      selectAllTypes,
      closeFilter,
      footerHeight,
      selectOrUnselectType,
      useContentTypeGroups,
    };
  },
});
