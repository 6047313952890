import { render, staticRenderFns } from "./PatientEncounter.vue?vue&type=template&id=2ea67b6a&scoped=true"
import script from "./PatientEncounter.vue?vue&type=script&lang=ts"
export * from "./PatientEncounter.vue?vue&type=script&lang=ts"
import style0 from "./PatientEncounter.vue?vue&type=style&index=0&id=2ea67b6a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ea67b6a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VCard,VContainer,VProgressCircular,VRow,VSelect,VTab,VTabs,VTabsSlider,VTooltip})
