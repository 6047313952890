import axios from "axios";

class SearchService {
  async getSearchContent (data) {
    return await axios
      .post("api/searchContent", data)
      .then(r => r.data)
      .catch(e => console.log(e));
  }
}

export default new SearchService();
