
import { defineComponent } from "vue";
export default defineComponent({
  name: "ContentTypeFilterAll",
  props: {
    contentTypes: {
      type: Array<string>,
      default: () => [],
    },
    allContentTypesSelected: {
      type: Boolean,
      default: false,
    },
    selectedContentTypes: {
      type: Array<string>,
      default: () => [],
    },
  },
});
