
import { defineComponent, computed } from "vue";
import contentTypesModule from "@/store/modules/ContentTypes/contentTypes";
import { ContentTypeGroup, contentTypeGroupings } from "@/models/contentType/contentTypeGroups";
export default defineComponent({
  name: "ContentTypeFilter",
  props: {
    allContentTypesSelected: {
      type: Boolean,
      default: false,
    },
    contentTypes: {
      type: Array<string>,
      default: () => [],
    },
    selectedContentTypes: {
      type: Array<string>,
      default: () => [],
    },
  },
  setup (props) {
    const contentTypeIds = contentTypesModule.getContentTypeIds;
    const contentGroups = computed(() => {
      return contentTypeGroupings.filter((group: ContentTypeGroup) => {
        const ids: number[] = group.ids;
        if (contentTypeIds.some(id => ids.includes(id))) {
          return true;
        }
        return false;
      }).sort((a, b) => a.name.localeCompare(b.name));
    });

    const getValueIfSelected = (ids: number[]) => {
      const idsSelected = props.contentTypes.map(type => { return { type, id: contentTypesModule.getContentTypeIdByContentTypeName(type) }; });
      const idsAllowedAndSelected = idsSelected.filter(group => ids.includes(group.id));
      return idsAllowedAndSelected.map(group => group.type);
    };

    const isSelected = (ids: number[]) => {
      return props.selectedContentTypes.some(type => getValueIfSelected(ids).includes(type));
    };

    return {
      contentGroups,
      isSelected,
      getValueIfSelected,
    };
  },
});

