export interface ContentTypeGroup {
  ids: number[];
  name: string;
}

export const contentTypeGroupings: ContentTypeGroup[] = [
  {
    name: "Client Custom Content",
    ids: [22, 165, 205, 295],
  },
  {
    name: "Drug Sheets",
    ids: [203],
  },
  {
    name: "Exit-Writer",
    ids: [82],
  },
  {
    name: "HealthSheets",
    ids: [1, 3, 34, 35, 56, 85, 90, 92, 134, 135, 160, 161, 216, 217],
  },
  {
    name: "KidsHealth",
    ids: [187, 202],
  },
  {
    name: "Lab Tests",
    ids: [167],
  },
  {
    name: "Videos",
    ids: [138, 192, 193, 194, 198, 207, 218],
  },
  {
    name: "Video Sheets",
    ids: [188, 200, 201, 204, 209],
  },
];
