import axios from "axios";
class EditContentService {
  async postEditContent (material) {
    return await axios
      .post("api/editedContent", {
        content: material.content,
        contentId: material.contentId,
        contentTypeId: material.contentTypeId,
      })
      .then(r => r.data)
      .catch(e => console.log(e));
  }

  async getEditContent () {
    return await axios
      .get("api/editedContent")
      .then(r => r.data)
      .catch(e => console.log(e));
  }

  async deleteEditContent (data) {
    return await axios
      .delete(`api/editedContent/${data.contentId}/${data.contentTypeId}`)
      .then(r => r.data)
      .catch(e => console.log(e));
  }
}

export default new EditContentService();
